import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/modules/alerts/confirm-dialog/confirm-dialog.component';
import { ProspektrApi } from '../api/prospektr.api';
import { CampaignCandidate } from '../interface/campaign.interface';
import { BackendResponse } from '../interface/common.interface';
import { CampaignState } from '../state/campaign.state';
import { SubscriptionState } from '../state/subscription.state';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(
    private prospektrApi: ProspektrApi,
    private campaignState: CampaignState,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private subscriptionState: SubscriptionState
  ) { }

  fetchCampaigns(): void {
    this.campaignState.isLoadingCampaignListValue = true;
    this.prospektrApi
      .getCampaignList()
      .pipe(
        finalize(() => {
          this.campaignState.isLoadingCampaignListValue = false;
        })
      )
      .subscribe(
        (res) => {
          if (res && res.status == 'OK' && Array.isArray(res.data) && res.data.length) {
            this.campaignState.campaignListValue = res.data;
          } else {
            this.campaignState.campaignListValue = null;
          }
        },
        (err) => {
          this.openSnackBar(err.error.message, 'snackbar-error');
          this.campaignState.campaignListValue = null;
        }
      );
  }

  getCampaignCandidates(campaignId: string): Observable<{ isLoading?: boolean; data?: Array<CampaignCandidate> }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getCampaignCandidates(campaignId)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe((res: any) => {
          observer.next({ data: res.data });
        });
    });
  }

  createCampaign(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .createCampaign(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            observer.next({ data: res.data });
            this.fetchCampaigns();
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(err?.error?.message || 'Failed to create campaign', 'snackbar-error');
          }
        );
    });
  }

  updateCampaign(campaignId, campaign): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateCampaign(campaignId, campaign)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            observer.next({ data: res.data ? res.data : campaignId });
            this.fetchCampaigns();
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar('Failed to update campaign', 'snackbar-error');
          }
        );
    });
  }

  createCampaignUsers(params, notify = true): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .createCampaignUsers(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (notify) {
              this.openSnackBar(res.message, 'snackbar-success');
            }
            this.campaignState.isLoadingAddCampaignUserValue = false;
          },
          (err) => {
            let message = 'Failed to create campaign';
            if (err && err.error && err.error.message) message = err.error.message;
            this.openSnackBar(message, 'snackbar-error');
            this.campaignState.isLoadingAddCampaignUserValue = false;
          }
        );
    });
  }

  launchCampaign(campaignId: string, frequency: string = null) {
    return new Observable((observer) => {
      if (this.subscriptionState.planUsageValue.campaignUsage >= this.subscriptionState.planUsageValue.campaignLimit) {
        this.openSnackBar('Please upgrade your plan', 'snackbar-warning');
        return observer.complete();
      } else {
        const planUsage = this.subscriptionState.planUsageValue;
        (planUsage.campaignUsage as number)++;
        this.subscriptionState.planUsageValue = planUsage;
      }

      this.prospektrApi
        .launchCampaign(campaignId, frequency)
        .pipe(
          finalize(() => {
            observer.next();
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            this.fetchCampaigns();
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(err.error.message || 'Failed to launch campaign', 'snackbar-error');
            this.fetchCampaigns();
          }
        );
    });
  }

  deleteCampaign(campaignId: string) {
    this.prospektrApi.deleteCampaign(campaignId).subscribe(
      (res: BackendResponse) => {
        this.openSnackBar(res.message, 'snackbar-success');
        this.fetchCampaigns();
      },
      (err) => {
        this.openSnackBar('Failed to delete campaign', 'snackbar-error');
      }
    );
  }

  openDeleteCampaignDialog(id) {
    let message: string;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        type: 'deleteCampaign',
        message: 'The selected campaign will be deleted',
        title: 'Do you want to delete?',
        proceedAction: (status, callBack) => {
          if (status) {
            this.deleteCampaign(id);
            callBack({ status: true });
            dialogRef.close();
          } else {
            return false;
          }
        },
        proceedCancelAction: () => {
          dialogRef.close();
        },
      },
    });
  }

  updateCampaignUsers(params, notify = true) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateCampaignUsers(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (notify) {
              this.openSnackBar(res.message, 'snackbar-success');
            }
          },
          (err) => {
            this.openSnackBar(err.error.message || 'Failed to update campaign', 'snackbar-error');
          }
        );
    });
  }

  getCampaignInfo(params): Observable<any> {
    return new Observable((observer) => {
      let result = [];
      observer.next({ isLoading: true });
      this.prospektrApi
        .campaignInfo(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false, response: result });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            result = res.data;
            if (params.campaignId) {
              if (res && res.data) {
                this.campaignState.campaignInfoValue = res;
              } else {
                this.campaignState.campaignInfoValue = null;
              }
            }
          },
          (err) => {
            this.campaignState.campaignInfoValue = null;
          }
        );
    });
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

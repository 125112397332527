import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { from } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { PropertyState } from 'src/app/workspace/state/property.state';
import { environment } from 'src/environments/environment';
import { PropertyService } from '../../service/property.service';

@Component({
  selector: 'app-listings-card',
  templateUrl: './listings-card.component.html',
  styleUrls: ['./listings-card.component.scss'],
})
export class ListingsCardComponent implements OnInit, OnChanges {
  @Input() actions: any;
  @Input() isSelectable: any;
  @Input() isSelected: any;
  @Input() property: any;
  @Input() detailsRequired: any;
  @Input() images: string[] = [];
  @Input() type?: 'watchlist' | 'basic' = 'basic';

  @Output() toggleFavorite = new EventEmitter<string>();
  @Output() triggeredActionCopy = new EventEmitter<any>();
  @Output() triggeredActionMove = new EventEmitter<any>();
  @Output() triggeredActionDelete = new EventEmitter<any>();
  @Output() toggleSelectAll = new EventEmitter<any>();
  @Output() toggleSelectCard = new EventEmitter<any>();

  listingImageBaseUrl = environment.listingImageBaseUrl;
  imageBaseUrl = environment.mediaUrl + environment.s3Upload.project + '&ImageID=';
  translateX = 0;
  currentIndex = 0;
  notificationList: Array<any>;
  selected: any;
  selection = new SelectionModel<any>(true, []);
  showCheckbox: boolean = false;
  isDownloading: boolean = false;

  constructor(
    private propertyState: PropertyState,
    private propertyService: PropertyService,
    private http: HttpClient
  ) { }

  ngOnInit(): void { }

  followProperty(property) {
    this.toggleFavorite.emit(property);
  }

  openPropertyDetails(property) {
    if (this.detailsRequired == false) {
      return;
    }
    property.logInput = true;
    property.PMXPropertyId = property.PMXPropertyId ? property.PMXPropertyId : '';
    if (property && property.PMXPropertyId) {
      this.propertyState.propertyDetailsSheetInput = property;
    } else {
      delete property.PMXPropertyId;
      property.address = property.fullAddress ? property.fullAddress : '';
      this.propertyState.propertyDetailsSheetInput = property;
    }
  }
  selectAll() {
    this.toggleSelectAll.emit();
  }

  toggleSelect(property, value) {
    this.selected = {
      id: property.id,
      selected: value,
    };
    this.toggleSelectCard.emit(this.selected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.property?.currentValue != changes?.property?.previousValue) {
      let propertyValue = changes?.property?.currentValue.notificationList;
      const newArr = propertyValue?.sort(
        (a, b) => new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
      );
      this.notificationList = [...new Map(newArr?.map((item) => [item['referenceType'], item])).values()];

      let propertyFilterClassParams = {
        Lst_specialListingConditions: changes?.property?.currentValue?.lstSpecialListingConditions,
        Lst_StandardStatus: changes?.property?.currentValue?.lstStandardStatus,
        Fcl_Status: changes?.property?.currentValue?.fclStatus,
        Fcl_EndDate: changes?.property?.currentValue?.fclEndDate,
      };
      let filterStyleClassFromList = this.propertyService.getPropertyStatus(propertyFilterClassParams);
      this.property.filterStyleClass = filterStyleClassFromList;
    }
  }

  actionTrigger(action) {
    if (action == 'copy') {
      this.triggeredActionCopy.emit(action);
    } else if (action == 'move') {
      this.triggeredActionMove.emit(action);
    } else if (action == 'delete') {
      this.triggeredActionDelete.emit(action);
    }
  }

  downloadAllImages(images: string[], name: string): void {
    this.isDownloading = true;
    const zip = new JSZip();
    const imageFolder = zip.folder(name) || zip; // Create a folder with the given name

    from(images).pipe(
      concatMap((image, index) =>
        this.downloadImageAsBlob(this.imageBaseUrl + image).pipe(
          tap((blob) => {
            const contentType = blob.type;
            const imageType = contentType.split('/')[1];
            const fileName = `${name} photo ${index + 1}.${imageType}`;
            imageFolder.file(fileName, blob);
          })
        )
      ),
      finalize(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${name}.zip`);
          this.isDownloading = false; // Hide the loader after download is complete
        });
        console.log('All images downloaded and zipped');
      })
    ).subscribe({
      next: () => { },
      error: (err) => {
        this.isDownloading = false; // Hide the loader in case of error
        console.error('Error downloading image', err);
      }
    });
  }

  downloadImageAsBlob(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  // downloadAllImages(images, name): void {
  //   from(images).pipe(
  //     concatMap((image, index) =>
  //       this.downloadImage(this.imageBaseUrl + image, name + ' photo ' + (index + 1)).pipe(delay(500)) // Adjust delay as needed
  //     )
  //   ).subscribe({
  //     next: (res) => console.log('Image downloaded', res),
  //     error: (err) => console.error('Error downloading image', err),
  //     complete: () => console.log('All images downloaded')
  //   });
  // }

  // downloadImage(url: string, name: string) {
  //   return this.http.get(url, { responseType: 'blob' }).pipe(
  //     tap((downloadImg: Blob) => {
  //       const a = document.createElement('a');
  //       const contentType = downloadImg.type;
  //       const imageType = contentType.split('/')[1];
  //       const objectUrl = URL.createObjectURL(downloadImg);
  //       a.href = objectUrl;
  //       a.download = `${name}.${imageType}`;
  //       a.click();
  //       URL.revokeObjectURL(objectUrl);
  //     })
  //   );
  // }
}

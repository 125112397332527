import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import constants from 'src/constant.json';
import { Json } from '../../interface/common.interface';
import { ContactService } from '../../service/contact.service';
import { ContactState } from '../../state/contact.state';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
})
export class AddContactComponent implements OnInit {
  addContactForm: FormGroup;
  today = new Date();
  mortgageCreationInProgress: boolean = false;
  onDestroyNotifier$ = new Subject();
  autoCompleteInput: any;
  propertySelected: any;
  contactListOption = [];
  invalidAddress = false;
  constructor(
    public dialogRef: MatDialogRef<AddContactComponent>,
    private formBuilder: FormBuilder,
    private contactState: ContactState,
    private contactService: ContactService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.addContactForm = this.formBuilder.group({
      list: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(constants.regex.validEmail)]],
      address: [null, [Validators.required]],
    });
    this.contactState.contactGroupList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((response) => {
      // let filterResponse = this.filterContactGroups(response, this.filter);
      this.contactListOption = response;
    });
  }

  get addContactFormValidation() {
    return this.addContactForm.controls;
  }

  get contactFormControls() {
    return this.addContactForm.controls;
  }

  proceedCancelAction() {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
  getAddressComponents(property) {
    this.invalidAddress = false;
    this.autoCompleteInput = property.fullAddress;
    this.propertySelected = property;
    this.addContactForm.value.address = property.fullAddress;
    this.cdr.detectChanges();
  }

  getAddressComponentsFromStandardAddress(standardAddress: string): Json {
    let addressComponentList = standardAddress?.split(',');
    if (!addressComponentList || addressComponentList?.length < 3) return null;
    let stateAndZip = addressComponentList[2];
    let stateAndZipList = stateAndZip?.split(' ');
    let addressComponent: any = {
      StreetAddress: addressComponentList[0],
      City: addressComponentList[1],
      State: stateAndZipList[1],
      PostalCode: stateAndZipList[2],
    };
    return addressComponent;
  }

  saveContact() {
    const hasComma = this.autoCompleteInput.includes(',');
    if (!hasComma && this.addContactForm.value.address) {
      this.invalidAddress = true;
      return;
    }
    if (!this.addContactForm.valid) return false;
    const addressComponents = this.getAddressComponentsFromStandardAddress(this.addContactForm.value.address);
    let params = {
      contactGroupId: this.addContactForm.value.list,
      contactList: [
        {
          firstName: this.addContactForm.value.firstName,
          lastName: this.addContactForm.value.lastName,
          phone: this.addContactForm.value.phoneNumber,
          email: this.addContactForm.value.email,
          streetAddress: addressComponents.StreetAddress || null,
          city: addressComponents.City || null,
          state: addressComponents.State || null,
          zip: addressComponents.PostalCode || null,
        },
      ],
    };

    this.contactService
      .addContactList(params)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(({ isLoading, data }) => {
        this.dialogRef.close();
        // this.isDataLoading = isLoading;
      });
  }
}

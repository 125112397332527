import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { ProspektrApi } from '../api/prospektr.api';

import { Observable, Subscription, timer } from 'rxjs';
import { ContactGroup } from '../interface/contact.interface';
import { ContactState } from '../state/contact.state';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  verificationSub: Subscription;
  verificationObservable;

  constructor(private contactState: ContactState, private prospektrApi: ProspektrApi, private snackBar: MatSnackBar) { }

  //run in specific interval to after success of start address verification (run match)
  checkVerificationStatus(groupId, params) {
    this.verificationObservable = timer(0, 15000);
    this.verificationSub = this.verificationObservable.subscribe((observer) => {
      this.prospektrApi
        .getContactGroups(groupId)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res.status == 'OK' && res.data.status == 'completed') {
              this.verificationSub.unsubscribe();
              this.getContactFileList().subscribe((res) => { });
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }

  getContactGroupList(): Observable<{ isLoading?: boolean; data?: Array<ContactGroup> }> {
    return new Observable((observer) => {
      if (this.contactState.contactGroupListValue?.length) {
        observer.next({ isLoading: false, data: this.contactState.contactGroupListValue });
        return observer.complete();
      }

      observer.next({ isLoading: true });
      this.prospektrApi
        .getContactGroups()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res?.data });
            this.contactState.contactGroupListValue = res?.data;
          },
          (err) => {
            observer.next({ data: null });
            this.contactState.contactGroupListValue = null;
          }
        );
    });
  }

  //get file list details
  getContactFileList() {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getContactGroups()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              this.contactState.contactGroupListValue = res.data;
            } else {
              this.contactState.contactGroupListValue = null;
            }
          },
          (err) => {
            this.contactState.contactGroupListValue = null;
          }
        );
    });
  }

  getContactRecordList(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .listAddressCsvRecord(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            this.contactState.contactListValue = res?.data;
          },
          () => {
            this.contactState.contactListValue = null;
          }
        );
    });
  }

  getSelectedContacts(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .selectedContacts(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              this.contactState.allContactsListValue = res.data;
            } else {
              this.contactState.allContactsListValue = null;
            }
          },
          (err) => {
            this.contactState.allContactsListValue = null;
          }
        );
    });
  }

  getContactsList(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .contactsList(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              this.contactState.contactsListValue = res.data;
            } else {
              this.contactState.contactsListValue = null;
            }
          },
          (err) => {
            this.contactState.contactsListValue = null;
          }
        );
    });
  }

  addContactList(params): Observable<any> {
    let result;
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .addContactList(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false, result: result });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            this.openSnackBar(res.message, 'snackbar-success');
            this.contactState.contactsListValue['paginatedResults'].push(res?.data);
          },
          (err) => {
            if (err.error && err.error.message) {
              this.openSnackBar(err.error.message, 'snackbar-error');
            }
          }
        );
    });
  }

  deleteAddressCSV(idList) {
    return new Observable((observer) => {
      let response;
      observer.next({ isLoading: true });
      this.prospektrApi
        .deleteContactFileList(idList)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false, response: response });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            response = res;
            if (response.status == 'success') {
              this.openSnackBar('File deleted successfully', 'snackbar-success');
            }
          },
          (err) => {
            this.openSnackBar(err.message ? err.message : 'Error occurred', 'snackbar-error');
          }
        );
    });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

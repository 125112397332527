<div class="row w-100" [title]="property.fullAddress" (click)="openPropertyDetails(property)">
  <div class="row w-100">
    <div class="col-md-4 col-sm-4 col-xs-12">
      <div class="card-image">
        <button
          *ngIf="!actions && detailsRequired"
          #toggleFavorite
          mat-icon-button
          class="follow-btn"
          aria-label="watch this property"
          (click)="followProperty(property); $event.stopPropagation()"
        >
          <mat-icon class="material-icons-new outline-favorite" *ngIf="!property.isFavorite"></mat-icon>
          <mat-icon class="text-danger" *ngIf="property.isFavorite">favorite</mat-icon>
        </button>
        <button
          *ngIf="actions"
          #toggleFavorite
          mat-icon-button
          class="follow-btn"
          aria-label="watch this property"
          (click)="actionTrigger('delete'); $event.stopPropagation()"
        >
          <!-- <mat-icon class="material-icons-new outline-favorite" *ngIf="!isFavorite"></mat-icon> -->
          <mat-icon class="text-danger">favorite</mat-icon>
        </button>
        <mat-checkbox
          *ngIf="isSelectable"
          color="primary"
          class="select-btn"
          (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelect(property, $event.checked) : ''"
          [checked]="isSelected"
        >
        </mat-checkbox>

        <img class="img-fluid mx-auto property-image" [src]="property?.imgUrl || '~/../assets/images/noPhoto.jpeg'" />
        <!-- <img
        class="img-fluid mx-auto property-image"
        [src]="listingImageBaseUrl + '?ListingId=' + (property.lstListingId || 'null') + '&imageName=1&height=219'"
      /> -->
        <div class="property-badge-wrapper">
          <!-- <span class="property-badge sale-type"> Home Details </span> -->
          <ng-container *ngFor="let notification of notificationList">
            <span
              class="property-badge"
              [ngClass]="notification.referenceType == 'recommendation' ? 'badge-recommendation' : 'badge-alert'"
              *ngIf="notification?.displayText?.toLowerCase() != property?.lstStandardStatus?.toLowerCase()"
            >
              {{ notification.displayText }}
            </span>
          </ng-container>

          <ng-container *ngIf="property.hasOwnProperty('viewStatus'); else noViewStatus">
            <span *ngIf="property.viewStatus == true" class="property-badge badge-viewStatus"> Investor Viewed </span>
          </ng-container>

          <ng-template #noViewStatus>
            <!-- <span class="property-badge sale-type"> Home Details </span> -->
            <!-- <span
            *ngIf="property.filterStyleClass == 'listing'"
            class="property-badge sale-type"
            [ngClass]="property.filterStyleClass"
          >
            {{ property.lstStandardStatus }}
          </span>
          <span
            *ngIf="property.filterStyleClass == 'sold'"
            class="property-badge sale-type"
            [ngClass]="property.filterStyleClass"
          >
            Sold
          </span>
          <span
            *ngIf="property?.filterStyleClass == 'distressed'"
            class="property-badge sale-type"
            [ngClass]="property.filterStyleClass"
          >
            {{ property?.fclStatus || property.lstSpecialListingConditions | titlecase }}
          </span>
          <span
            *ngIf="property?.filterStyleClass == 'hud'"
            class="property-badge sale-type"
            [ngClass]="property.filterStyleClass"
          >
            {{ property?.lstSpecialListingConditions | titlecase }}
          </span> -->
          </ng-template>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-4 col-xs-12 pl-0">
      <div class="card-content mb-2 mx-0">
        <!-- <div class="pricing">
      <h5>${{ property.lstListPrice || property.currentAVMValue | number }}</h5>
      <div *ngIf="actions">
        <button class="float-right" mat-icon-button [matMenuTriggerFor]="favMenu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #favMenu="matMenu" id="fldproplist_action" class="mt-0" xPosition="before">
          <button *ngFor="let action of actions" mat-menu-item (click)="actionTrigger(action)">
            <i *ngIf="action === 'copy'" class="fa fa-clone mr-1" aria-hidden="true"></i>
            <i class="fa fa-arrows-alt mr-1" *ngIf="action === 'move'" aria-hidden="true"></i>
            <i class="fa fa-trash-o mr-1" *ngIf="action === 'delete'" aria-hidden="true"></i>
            <span>{{ action | titlecase }} </span>
          </button>
        </mat-menu>
      </div>
    </div> -->
        <div class="action-menu">
          <!-- <button class="float-right" mat-icon-button [matMenuTriggerFor]="favMenu" (click)="$event.stopPropagation()"> -->
          <!-- <mat-icon>more_vert</mat-icon> -->
          <!-- </button> -->
          <mat-menu #favMenu="matMenu" id="fldproplist_action" class="mt-0" xPosition="before">
            <button *ngFor="let action of actions" mat-menu-item (click)="actionTrigger(action)">
              <i *ngIf="action === 'copy'" class="fa fa-clone mr-1" aria-hidden="true"></i>
              <i class="fa fa-arrows-alt mr-1" *ngIf="action === 'move'" aria-hidden="true"></i>
              <i class="fa fa-trash-o mr-1" *ngIf="action === 'delete'" aria-hidden="true"></i>
              <span>{{ action | titlecase }} </span>
            </button>
          </mat-menu>
        </div>
        <div class="mt-3">
          <!-- <p class="mt-1 prop-name">{{ property.fullAddress }}</p> -->
          <h4>{{ property.fullAddress }}</h4>
          <!-- <div class="mb-3" style="font-weight: 500">Lauderhill, FL 33313</div> -->
          <div class="d-inline-block propinfo_amntis mb-1">
            <p class="my-0">
              <b>{{ property.BedroomsTotal || '-' }}</b> <small> Bed</small>
            </p>
          </div>
          <div class="d-inline-block propinfo_amntis mb-1">
            <p class="my-0">
              <b>{{ property.BathroomsFull || '-' }}</b
              ><small> Bath</small>
            </p>
          </div>
          <div class="d-inline-block propinfo_amntis mb-1">
            <p class="my-0">
              <b>{{ (property.LivingArea | number) || '-' }}</b
              ><small> Sqft</small>
            </p>
          </div>
          <div class="d-inline-block propinfo_amntis mb-1">
            <p class="my-0">
              <b>{{ (property.LotSizeSqFt | number) || '-' }}</b
              ><small> Sqft.lot </small>
            </p>
          </div>
        </div>

        <div class="d-inline-block">
          <p class="my-0 mb-1">
            Package : <b>{{ (property.plansData?.name | toString) || '-' }}</b>
          </p>
          <p class="my-0 mb-1">
            Listing Status : <b>{{ (property?.stripePaymentStatus | toString) || 'Not paid' }}</b>
          </p>
        </div>
        <button
          mat-menu-item
          *ngIf="property.ImageIds && property.ImageIds.length > 0"
          (click)="downloadAllImages(property.ImageIds, property.fullAddress); $event.stopPropagation()"
          [disabled]="isDownloading"
        >
          <div *ngIf="!isDownloading">
            <i class="fa fa-download mr-1" aria-hidden="true"></i>
            <span>
              <b> Download all {{ property.ImageIds.length }} images </b>
            </span>
          </div>

          <div class="d-flex" *ngIf="isDownloading">
            <mat-progress-spinner class="mr-1" mode="indeterminate" diameter="20"></mat-progress-spinner>
            <span><b>Downloading...</b></span>
          </div>
        </button>
      </div>
    </div>

    <div
      *ngIf="property.ImageIds && property.ImageIds.length > 0"
      (click)="$event.stopPropagation()"
      class="col-md-4 col-sm-4 col-xs-12 pl-0"
      style="background-color: #ebeaea"
    >
      <div></div>
      <ngb-carousel>
        <ng-template ngbSlide *ngFor="let image of property.ImageIds">
          <img class="img-fluid mx-auto property-image slider-img" [src]="imageBaseUrl + image" alt="slide" />
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>
